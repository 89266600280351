import React, { useState, useEffect } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { useForm } from "react-hook-form";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import ModalSpinner from "../../ModalSpinner";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { axiosPrivate } from "../../../common/axiosPrivate";
import ModalErrorEditarAnexosContratos from "./ModalErrorEditarAnexosContratos";

export default function ModalEditarAnexoContrato({
  isVisible,
  onClose,
  modalData,
  isAnexosContrato,
  resetearFiltros,
}) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: modalData,
  });

  const [enabled, setEnabled] = useState({
    plazoContrato: false,
    cargo: false,
    seccion: false,
    jornada: false,
    haber: false,
  });

  const plazoContratoId = watch("plazoContratoId");
  const cargoId = watch("cargoId");
  const seccionId = watch("seccionId");
  const jornadaId = watch("jornadaId");

  useEffect(() => {
    Object.keys(enabled).forEach((field) => {
      if (enabled[field]) {
        trigger(`${field}Id`);
      } else {
        setValue(`${field}Id`, 'disabled', { shouldValidate: true });
        clearErrors(`${field}Id`);
      }
    });
  }, [enabled, setValue, clearErrors, trigger]);

  const handleCheckboxChange = (field) => {
    setEnabled((prevState) => ({ ...prevState, [field]: !prevState[field] }));
  };
  const listaHaberes = useSelector((state) => state.getHaberes);
  const jornadas = useSelector((state) => state.getJornadas);
  const cargos = useSelector((state) => state.getCargos);
  const secciones = useSelector((state) => state.getSecciones);

  const [showModalError, setShowModalError] = useState(false);
  const [errorRecibidoContratos, setErrorRecibidoContratos] = useState([]);
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true);
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const validacionTextInput = {
    required: { value: true, message: "Campo obligatorio" },
    // pattern: {
    //   value: /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g,
    //   message: "Solo se permiten letras",
    // },
  };

  const rutValidations = {
    required: { value: true, message: "Debe ingresar un rut" },
    pattern: {
      value: /^(\d{1,2}(?:[\.]?\d{3}){2}-[\dkK])$/,
      message:
        "El formato del RUT es incorrecto (ej. 18.405.136-K o 18405136-K)",
    },
    validate: {
      rutValido: (v) =>
        UtilsFunctions.validateRut(v) || "El RUT ingresado no es válido",
    },
  };

  const formatter = new Intl.NumberFormat("es-CL", {
    styles: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
  });


  const onSubmit = async (data) => {
    if (
      !enabled.cargo &&
      !enabled.plazoContrato &&
      !enabled.jornada &&
      !enabled.seccion &&
      !enabled.haber
    ) {
      toast.error("Debe editar por lo menos un campo", estilosAlerta);
      return null;
    }
    try {
      const payload = {
        isAnexosContrato: isAnexosContrato,
        contratosAnexosIds: modalData,
        id: modalData.id,
        fechaNuevoAnexo: data.fechaNuevoAnexo,
        fechaTerminoNuevoAnexo:
          data.plazoContratoId === "1" ? data.fechaTerminoNuevoAnexo : null,
        nuevoCargo: enabled.cargo,
        nuevoCargoId: enabled.cargo ? data.cargoId : null,
        nuevoPlazoContrato: enabled.plazoContrato,
        nuevoPlazoContratoId: enabled.plazoContrato
          ? data.plazoContratoId
          : null,
        nuevaJornada: enabled.jornada,
        nuevaJornadaId: enabled.jornada ? data.jornadaId : null,
        nuevaSeccion: enabled.seccion,
        nuevaSeccionId: enabled.seccion ? data.seccionId : null,
        actualizarHaber: enabled.haber,
        haber: {
          haberId: enabled.haber ? data.haberId : null,
          monto: enabled.haber ? data.montoPesos.replace(/\./g, "").replace("$", "") : null,
        },
      };
      toast.loading("Actualizando Contratos", estilosAlerta);
      const response = await axiosPrivate.post(
        `${api_service}/api/v1/contratos/masivos`,
        payload,
        Authorization
      );
      if (response.status === 202) {
        setErrorRecibidoContratos(response.data);
        toast.dismiss();
        toast.error(
          "Algunos contratos no pudieron actualizarse",
          estilosAlerta
        );
        setShowModalError(true);
      } else if (response.status === 200) {
        toast.dismiss();
        toast.success("Contratos actualizados con éxito", estilosAlerta);
        setRefreshTable(!refreshTable);
        onClose();
        resetearFiltros();
      }
      //   setBotonGuardarHabilitado(true);
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al actualizar Contratos",
        estilosAlerta
      );
      console.log(error);
      // setBotonGuardarHabilitado(true);
    }
  };

  const loadingModal = modalData ? true : false;

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="primerDivModal">
          <div className="segundoDivModal max-w-xl">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[90vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
                <h3 className="titulo-Modal">Actualizar Anexo/Contrato</h3>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
                {!loadingModal && <ModalSpinner />}

                {/* Plazo */}
                <div className="flex flex-wrap">
                  <div className="w-full md:w-2/12 px-3 text-center items-center">
                    <label className="text-left pb-2 block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Habilitar
                    </label>
                    <input
                      type="checkbox"
                      className="rounded-md"
                      onChange={() => handleCheckboxChange("plazoContrato")}
                    />
                  </div>
                  <div className="w-full relative md:w-6/12 px-3 mb-6 md:mb-2">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="duracionContrato"
                    >
                      Plazo Contrato
                    </label>
                    <select
                      {...register("plazoContratoId", {
                        required: enabled.plazoContrato ? "Obligatorio" : false,
                      })}
                      disabled={!enabled.plazoContrato}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      {!enabled.plazoContrato && (
                        <option value="disabled" hidden>
                          Deshabilitado
                        </option>
                      )}
                      {enabled.plazoContrato && (
                        <>
                          <option value="" hidden>
                            Seleccionar
                          </option>
                          <option value="1">Fijo</option>
                          <option value="2">Indefinido</option>
                        </>
                      )}
                    </select>
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.plazoContratoId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.plazoContratoId
                        ? errors.plazoContratoId.message
                        : "hidden"}
                    </span>
                  </div>
                </div>

                {/* Cargo */}
                <div className="flex flex-wrap">
                  <div className="w-full md:w-2/12 pt-8 px-3 text-center items-center">
                    <input
                      type="checkbox"
                      className="rounded-md"
                      onChange={() => handleCheckboxChange("cargo")}
                    />
                  </div>
                  <div className="w-full relative md:w-6/12 px-3 mb-6 md:mb-2">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="duracionContrato"
                    >
                      Cargo
                    </label>
                    <select
                      {...register("cargoId", {
                        required: enabled.cargo ? "Obligatorio" : false,
                      })}
                      disabled={!enabled.cargo}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      {!enabled.cargo && (
                        <option value="disabled" hidden>
                          {" "}
                          Deshabilitado{" "}
                        </option>
                      )}
                      <option hidden value="">
                        Seleccionar
                      </option>
                      {cargos?.map((el) => (
                        <>
                          <option value={el.id} key={el.id}>
                            {el.nombre}
                          </option>
                        </>
                      ))}
                    </select>
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.cargoId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.cargoId ? errors.cargoId.message : "hidden"}
                    </span>
                  </div>
                </div>

                {/* Seccion */}
                <div className="flex flex-wrap">
                  <div className="w-full md:w-2/12 pt-8 px-3 text-center items-center">
                    <input
                      type="checkbox"
                      className="rounded-md"
                      onChange={() => handleCheckboxChange("seccion")}
                    />
                  </div>
                  <div className="w-full relative md:w-6/12 px-3 mb-6 md:mb-2">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="duracionContrato"
                    >
                      Sección
                    </label>
                    <select
                      {...register("seccionId", {
                        required: enabled.seccion ? "Obligatorio" : false,
                      })}
                      disabled={!enabled.seccion}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      {!enabled.seccion && (
                        <option value="disabled" hidden>
                          {" "}
                          Deshabilitado{" "}
                        </option>
                      )}
                      <option hidden value="">
                        Seleccionar
                      </option>
                      {secciones?.map((el) => (
                        <>
                          <option value={el.id} key={el.id}>
                            {el.nombre}
                          </option>
                        </>
                      ))}
                    </select>
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.seccionId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.seccionId ? errors.seccionId.message : "hidden"}
                    </span>
                  </div>
                </div>

                {/* Jornada */}
                <div className="flex flex-wrap">
                  <div className="w-full md:w-2/12 pt-8 px-3 text-center items-center">
                    <input
                      type="checkbox"
                      className="rounded-md"
                      onChange={() => handleCheckboxChange("jornada")}
                    />
                  </div>
                  <div className="w-full relative md:w-6/12 px-3 mb-6 md:mb-2">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="duracionContrato"
                    >
                      Jornada Laboral
                    </label>
                    <select
                      {...register("jornadaId", {
                        required: enabled.jornada ? "Obligatorio" : false,
                      })}
                      disabled={!enabled.jornada}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      {!enabled.jornada && (
                        <option value="disabled" hidden>
                          {" "}
                          Deshabilitado{" "}
                        </option>
                      )}
                      <option hidden value="">
                        Seleccionar
                      </option>
                      {jornadas?.map((el) => (
                        <>
                          <option value={el.id} key={el.id}>
                            {el.nombre}
                          </option>
                        </>
                      ))}
                    </select>
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.jornadaId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.jornadaId ? errors.jornadaId.message : "hidden"}
                    </span>
                  </div>
                </div>

                {/* Haber */}
                <div className="flex flex-wrap">
                  <div className="w-full md:w-2/12 pt-8 px-3 text-center items-center">
                    <input
                      type="checkbox"
                      className="rounded-md"
                      onChange={() => {
                        handleCheckboxChange("haber");
                        setValue("montoPesos", "");
                      }}
                    />
                  </div>
                  <div className="w-full md:w-6/12 px-3">
                    <label className="dark:text-white text-hercules-light-font-secondary">
                      Haber
                    </label>
                    <select
                      {...register("haberId", {
                        required: enabled.jornada ? "Obligatorio" : false,
                      })}
                      disabled={!enabled.haber}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      {!enabled.haber && (
                        <option value="disabled" hidden>
                          {" "}
                          Deshabilitado{" "}
                        </option>
                      )}
                      <option hidden value="">
                        Seleccionar
                      </option>
                      {listaHaberes.map((haber, i) => (
                        <option value={haber.id} key={i}>
                          {haber.nombre}
                        </option>
                      ))}
                    </select>
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.haberId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.haberId ? errors.haberId.message : "hidden"}
                    </span>
                  </div>

                  <div className="w-full md:w-4/12 px-3">
                    <label className="dark:text-white text-hercules-light-font-secondary">
                      Monto
                    </label>
                    <input
                      disabled={!enabled.haber}
                      {...register("montoPesos", {
                        validate: (value) => {
                          if (value === "" || value === "$" || value === "$0") {
                            return "Mayor a 0";
                          }
                          return true;
                        },
                        onChange: () => {
                          setValue(
                            "montoPesos",
                            "$" +
                              formatter.format(
                                getValues("montoPesos")?.replace(/\D/g, "")
                              )
                          );
                        },
                      })}
                      className={`appearance-none block w-full ${
                        enabled.haber
                          ? "bg-gray-200 border-gray-200"
                          : "bg-gray-400 border-gray-400"
                      } text-gray-700 border  rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                      type="text"
                    />
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.montoPesos && "opacity-0 invisible"
                      }`}
                    >
                      {errors.montoPesos ? errors.montoPesos.message : "hidden"}
                    </span>
                  </div>
                </div>

                {/* Fecha Inicio y Termino */}
                <div className="flex flex-wrap mt-3">
                  <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Fecha Inicio del Nuevo Anexo
                    </label>
                    <input
                      {...register("fechaNuevoAnexo", validacionTextInput)}
                      className="appearance-none block w-full bg-gray-200 border-gray-200 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                      type="date"
                    />
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.fechaNuevoAnexo && "opacity-0 invisible"
                      }`}
                    >
                      {errors.fechaNuevoAnexo
                        ? errors.fechaNuevoAnexo.message
                        : "hidden"}
                    </span>
                  </div>

                  <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Fecha Termino del Nuevo Anexo
                    </label>
                    <input
                      disabled={watch("plazoContratoId") !== "1"}
                      {...register("fechaTerminoNuevoAnexo", {
                        required:
                          plazoContratoId === "1" && enabled.plazoContrato
                            ? "Obligatorio"
                            : false,
                      })}
                      className={`appearance-none block w-full ${
                        watch("plazoContratoId") === "1"
                          ? "bg-gray-200 border-gray-200"
                          : "bg-gray-400 border-gray-400"
                      } text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white`}
                      type="date"
                    />
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.fechaTerminoNuevoAnexo && "opacity-0 invisible"
                      }`}
                    >
                      {errors.fechaTerminoNuevoAnexo &&
                      plazoContratoId === "1" &&
                      enabled.plazoContrato
                        ? errors.fechaTerminoNuevoAnexo.message
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  Cerrar
                </button>
                <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${
                    !botonGuardarHabilitado
                      ? "bg-gray-500"
                      : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"
                  }  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </form>

      {showModalError && (
        <ModalErrorEditarAnexosContratos
          modalData={errorRecibidoContratos}
          isVisible={showModalError}
          onClose={() => setShowModalError(false)}
        />
      )}
    </>
  );
}
