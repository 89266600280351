import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { getCentroCostos, getCentrosCostosByEmpresaId, listaPeriodosHastaActual } from "../../Redux/Actions";
import UtilsFunctions from "../../utils/UtilsFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

export default function NuevoLibroRemuneraciones() {
  const {
    register,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      centroCostoId: 0,
      periodoAnyo: "",
    },
  });

  const { activeMenu, screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const empresaId = localStorage.getItem("empresaId");
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const dispatch = useDispatch();

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const AuthorizationFiniquitos = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
    responseType: "blob",
  };

  const campoObligatorio = {
    required: { value: true, message: "Obligatorio" },
  };

  useEffect(() => {
    try {
      getPeriodosCargados()
      dispatch(listaPeriodosHastaActual());
      dispatch(getCentroCostos());
      dispatch(getCentrosCostosByEmpresaId(empresaId));
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  const infoCCosto = useSelector((state) => state.centrosCostoEmpresa);
  const [mesesData, setMesesData] = useState([]);
  const [infoPeriodos, setInfoPeriodos] = useState([]);
  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();

  async function getPeriodosCargados(){
    const infoMeses = await axiosPrivate(`${api_service}/api/v1/periodos/cargados`, Authorization)
    setInfoPeriodos(infoMeses.data)
  }

  const handleYearChange = (e) => {
    const year = parseInt(e);
    const yearData = infoPeriodos.find((item) => item.anyo === year);
    if (yearData) {
      const filteredMonths = yearData.periodos.filter(
        (mes) => mes.datosPrevisionalesCargados === true
      );
      const transformedMonths = filteredMonths.map((month) => (
        UtilsFunctions.integerToMonth(month.mes)
      ));
      setMesesData(transformedMonths);

    } else {
      setMesesData([]);
    }
  };

  async function exportarLibro() {
    const data = getValues();
    const info = await axiosPrivate.get(
      `${api_service}/api/v1/periodos/mes/${data.numeroMes}/anyo/${data.periodoAnyo}`,
      Authorization
    );
    setValue("periodoId", info.data.id);

    // exportarLibroAnual(info.data.id)
    if (data.tipoLibro === "anual") {
      exportarLibroAnual(info.data.id);
    }
    if (data.tipoLibro === "revision") {
      exportarLibroRevision(info.data.id);
    }
    if (data.tipoLibro === "remuneracionesElectronica") {
      exportarLibroReumeracionesElectronica(info.data.id);
    }
  }

  async function exportarLibroAnual(periodoId) {
    const data = getValues();
    try {
      toast.loading("Descargando Libro de Remuneraciones anual", estilosAlerta);
      axiosPrivate({
        url: `${api_service}/api/v1/trabajadores/liquidaciones/libro-remuneraciones/anual/periodo/${periodoId}/empresa/${empresaId}/centroCosto/${data.centroCostoId}`,
        method: "GET",
        //   data: data,
        maxBodyLength: Infinity,
        headers: { Authorization: `Bearer ${tokenUser}`, ClientId: `${token}` },
        responseType: "blob", // importante
        onDownloadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Libro Remuneraciones Anual.csv");
        document.body.appendChild(link);
        link.click();
        toast.dismiss();
        toast.success("Libro Remuneraciones Anual descargado con éxito", estilosAlerta);
      });
    } catch (error) {
      console.log("Error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al descargar Libro de Remuneraciones anual",
          estilosAlerta
      );
    }
  }

  async function exportarLibroRevision(periodoId) {
    const data = getValues();
    toast.loading("Descargando Libro de Revision", estilosAlerta);
    axiosPrivate({
      url: `${api_service}/api/v1/trabajadores/liquidaciones/libro-remuneraciones/revision/periodo/${periodoId}/empresa/${empresaId}/centroCosto/${data.centroCostoId}`,
      method: "GET",
      //   data: data,
      maxBodyLength: Infinity,
      headers: { Authorization: `Bearer ${tokenUser}`, ClientId: `${token}` },
      responseType: "blob", // importante
      onDownloadProgress: (progressEvent) => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "LibroRevision.xlsx");
        document.body.appendChild(link);
        link.click();
        toast.dismiss();
        toast.success("Libro Remuneraciones Revision descargado con éxito", estilosAlerta);
      })
      .catch((error) => {
        console.log("Error", error);
        toast.dismiss();
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "Error al descargar Libro de Revision",
            estilosAlerta
        );
      });
  }

  async function exportarLibroReumeracionesElectronica(periodoId) {
    const data = getValues();
    toast.loading("Descargando Libro de Remuneraciones Electrónico", estilosAlerta);
    axiosPrivate({
      url: `${api_service}/api/v1/trabajadores/libro-remuneraciones-electronico/periodo/${periodoId}/empresa/${empresaId}/centro-costo/${data.centroCostoId}`,
      method: "POST",
      // data: payload,
      maxBodyLength: Infinity,
      headers: { Authorization: `Bearer ${tokenUser}`, ClientId: `${token}` },
      responseType: "blob", // importante
      onDownloadProgress: (progressEvent) => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log("percentCompleted", percentCompleted);
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Libro Remuneraciones Electronica.csv");
        document.body.appendChild(link);
        link.click();
        toast.dismiss();
        toast.success(
          "Libro Remuneraciones Electrónica descargado con éxito",
          estilosAlerta
        );
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss();
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "Error al descargar Libro de Remuneraciones Electrónica",
            estilosAlerta
        );
      });
  }

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 md:pr-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">
        Herramientas
      </div>
      <div className="titulo-Pagina">
        Libro de Remuneraciones
      </div>

      <div className="md:divide-y-[3px]">
        <form onSubmit={handleSubmit(exportarLibro)} className="pt-3 flex flex-wrap">

            <div className="flex flex-wrap w-full md:w-fit">
              <div className="w-4/12 md:w-4/12 md:min-w-[100px] md:max-w-[200px] pr-3 mb-3">
                <label
                  htmlFor="periodoId"
                  className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
                >
                  Periodo
                </label>
                  <select
                    {...register("periodoAnyo", {
                      onChange: (e) => {
                        setValue("periodoMes", "");
                        handleYearChange(e.target.value)
                      },
                      ...campoObligatorio,
                    })}
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option hidden value="">
                      Año
                    </option>
                    {anyosData.map((anyo, i) => (
                      <option value={anyo} key={i}>
                        {anyo}
                      </option>
                    ))}
                  </select>
                  <span
                    className={`dark:text-red-400 text-red-600 text-xs ${
                      !errors.periodoAnyo && "opacity-0 invisible"
                    }`}
                  >
                    {errors.periodoAnyo ? errors.periodoAnyo.message : "hidden"}
                  </span>
              </div>

              <div className="w-5/12 md:w-6/12 md:min-w-[130px] md:max-w-[200px] mt-6 md:mr-3 mb-3">
                <select
                  {...register("periodoMes", {
                    onChange: (e) => {
                      setValue("numeroMes", e.target.selectedIndex);
                    },
                    required: { value: true, message: "Obligatorio" },
                  })}
                  disabled={watch("periodoAnyo") === "" ? true : false}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option hidden value="">
                    Mes
                  </option>
                  {mesesData.map((mes, i) => (
                    <option value={mes} key={i}>
                      {mes}
                    </option>
                  ))}
                </select>
                <span
                  className={`dark:text-red-400 text-red-600 text-xs ${
                    !errors.periodoMes && "opacity-0 invisible"
                  }`}
                >
                  {errors.periodoMes ? errors.periodoMes.message : "hidden"}
                </span>
              </div>
            </div>

            <div className="w-full md:w-72 md:px-3 mb-6 md:mb-0">
              <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                Centro de Costo
              </label>
              <select
                {...register("centroCostoId", {
                  onChange: (e) => {
                    const centroCosto = infoCCosto[e.target.selectedIndex - 1];
                    setValue("centroCosto", centroCosto);
                  },
                })}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option hidden value="">
                  Seleccionar
                </option>
                <option value={"0"}>Todos</option>
                {infoCCosto.map((ccosto, i) => (
                  <option value={ccosto.id} key={i}>
                    {ccosto.nombre}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-full md:w-72 md:mx-3 mb-6 md:mb-0">
              <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                Libro
              </label>
              <select
                {...register("tipoLibro", {
                  required: { value: true, message: "Obligatorio" },
                  // onChange: (e) => {
                  //   const centroCosto = infoCCosto[e.target.selectedIndex - 1];
                  //   setValue("centroCosto", centroCosto);
                  // },
                })}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option hidden value="">
                  Seleccionar
                </option>
                <option value={"anual"}>Libro Remuneraciones Anual</option>
                <option value={"remuneracionesElectronica"}>Libro Remuneraciones Electrónico</option>
                <option value={"revision"}>Libro Remuneraciones Revisión</option>
              </select>
              <span
                  className={`dark:text-red-400 text-red-600 text-xs ${
                    !errors.tipoLibro && "opacity-0 invisible"
                  }`}
                >
                  {errors.tipoLibro ? errors.tipoLibro.message : "hidden"}
                </span>

            </div>

            <div className="w-7/12 md:w-48 md:px-3 md:mt-3 mb-3 md:mb-0">
              <button
                type="submit"
                className="mt-3 hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-in-out transition-all duration-400"
              >
                Exportar CSV
              </button>
            </div>
        </form>

        <div className="pt-7 mb-3">
          <Link to="/herramientas/libro-remuneraciones/mensual">
            <button className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-in-out transition-all duration-400">
              Ver Libro mensual
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
