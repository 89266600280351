import React, { useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  TbArrowsSort,
  TbSortAscending,
  TbSortDescending,
} from "react-icons/tb";
import GlobalFilter from "../../GlobalFilter";
import { BsDownload } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdLastPage,
  MdFirstPage,
} from "react-icons/md";
import { axiosPrivate } from "../../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import { useStateContext } from "../../../contexts/ContextProvider";
import { useForm } from "react-hook-form";
import ModalEditarAnexoContrato from "./EditarAnexosContratos";
// import ModalErrorActualizarHab from "./ModalErrorActualizarHab";
export default function TablaActualizarAnexosContratos({
  col,
  tableData,
  isAnexosContrato,
  filasSeleccionadas,
  resetearFiltros,
}) {
  // eslint-disable-next-line
  const columns = useMemo(() => col, []);
  const data = useMemo(() => [...tableData], [tableData]);
  const { screenSize } = useStateContext();
  const periodoMes = localStorage.getItem("periodoMes");
  const periodoAnyo = localStorage.getItem("periodoAnyo");
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true);

  const formatter = new Intl.NumberFormat("es-CL", {
    styles: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
  });

  const {
    register,
    getValues,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState,
  } = useForm({
    defaultValues: {
      radioAumentaDisminuye: "",
      radioValorPorcentaje: "",
    },
  });
  const { errors } = formState;

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const validacion = {
    required: { value: true, message: "Obligatorio" },
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    setPageSize,
    pageOptions,
    state,
    rows,
    selectedFlatRows,
    state: { selectedRowIds },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        selectedRowIds: filasSeleccionadas,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          disableGlobalFilter: true,
        },
        ...columns,
      ]);
    }
  );

  const { pageIndex, pageSize } = state;

  const { globalFilter } = state;

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  const [errorRecibido, setErrorRecibido] = useState([]);
  const [showModalActualizar, setShowModalActualizar] = useState(false);
  // const [showModalErrorActualizarHaberes, setShowModalErrorActualizarHaberes] = useState(false);

  const [arrayIdTrabajadores, setArrayIdTrabajadores] = useState([]);
  async function abrirModal() {
    if (!selectedFlatRows.length) {
      toast.dismiss();
      toast.error("Debe seleccionar al menos un Contrato", estilosAlerta);
      return null;
    }
    const nuevosTrabajadores = selectedFlatRows.map((e) => ({
      contratoId: e.original.contratoId ? e.original.contratoId : "",
      anexoContratoId: e.original.anexoContratoId ? e.original.anexoContratoId : "",
      rutTrabajador: e.original.rutTrabajador,
      nombreTrabajador: e.original.nombreTrabajador + " " + e.original.apellidoPaternoTrabajador + " " + e.original.apellidoMaternoTrabajador
    }));
    setArrayIdTrabajadores(nuevosTrabajadores);
    setShowModalActualizar(true);
  }

  return (
    <>
      <div className="bg-white dark:bg-hercules-dark-bg-100 border-t  border-l border-r border-gray-300 dark:border-dark-bg-200 h-12 flex rounded-t-md p-3">
        <>
          <span className="ml-auto ">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </span>
          <span className="mx-3 pt-1 text-xl dark:text-dark-text-100 text-black hover:cursor-pointer">
            <BiSearch />
          </span>
        </>
      </div>

      <div className="h-fix overflow-auto border-x-1  bg-white dark:bg-hercules-dark-bg-200  dark:border-dark-bg-200 border-gray-300 border-t-1">
        <table {...getTableProps()} className="w-full rounded-lg">
          <thead className="text-left sticky top-0 border-b-1 border-b-hercules-light-secondary dark:border-b-hercules-dark-secondary-button bg-hercules-light-table-header dark:bg-hercules-dark-bg-200">
            {headerGroups.map((headerGroup) => {
              const { key, ...restHeaderGroup } =
                headerGroup.getHeaderGroupProps();
              return (
                <>
                  <tr {...restHeaderGroup} className="h-10 flex-auto">
                    {headerGroup.headers.map((column) => {
                      const { key, ...restColumn } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );
                      return (
                        <React.Fragment key={key}>
                          <th
                            {...restColumn}
                            className="py-2 px-4 text-hercules-light-font-secondary font-medium dark:text-white"
                          >
                            <div className="flex">
                              <span className="">
                                {column.render("Header")}
                              </span>
                              <span className="ml-1 mt-auto mb-auto">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TbSortDescending />
                                  ) : (
                                    <TbSortAscending />
                                  )
                                ) : (
                                  <TbArrowsSort />
                                )}
                              </span>
                            </div>
                          </th>
                        </React.Fragment>
                      );
                    })}
                  </tr>
                  <tr {...headerGroup.getHeaderGroupProps()} className="h-10">
                    {headerGroup.headers.map((column) => {
                      const { key, ...restColumn } = column.getHeaderProps();
                      return (
                        <th {...restColumn} className="pl-4 pb-4">
                          <div className="w-fit pr-4">
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()} className="h-full ">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <>
                  <tr
                    {...row.getRowProps()}
                    className="group bg-white dark:bg-hercules-dark-bg-200   dark:text-dark-text-100 dark:border-b-hercules-dark-secondary-button border-b border-hercules-light-secondary text-black h-12 dark:hover:bg-white dark:hover:bg-opacity-10 transition duration-100 ease-in-out hover:bg-light-accent hover:bg-opacity-20"
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps}
                          className="py-2 px-4 text-base font-normal"
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className=" border-1 dark:border-t-hercules-dark-secondary-button border-t-hercules-light-secondary border-gray-300 dark:border-dark-bg-200 dark:bg-hercules-dark-bg-200   rounded-b-md h-14 flex bg-white">
        <div className="ml-auto py-3 flex">
          <p className="hidden sm:block pt-1 text-black dark:text-white">
            Filas por página
          </p>
          <select
            name=""
            id=""
            className="ml-2 h-8 pt-1.5 text-sm bg-white border rounded-sm"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 40, 50].map((pageSize, i) => (
              <option key={i} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block p-4 text-black dark:text-white">
          Página {pageIndex + 1} de {pageOptions.length}
        </div>
        <div className="flex mr-4 pl-4">
          <button
            className="bg-white text-black dark:text-white dark:bg-opacity-0 rounded-full hover:bg-gray-200 hover:text-black my-2 w-8 mr-6"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <MdFirstPage className="pl-1 text-2xl" />
          </button>
          <button
            className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 mr-6 w-8"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <MdArrowBackIos className="ml-2" />
          </button>
          <button
            className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 mr-6 w-8"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <MdArrowForwardIos className="ml-2" />
          </button>
          <button
            className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 w-8"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <MdLastPage className="pl-1 text-2xl" />
          </button>
        </div>
      </div>

      {/* <form className="w-full md:w-9/12 flex flex-wrap mt-3" onSubmit={handleSubmit(actualizarTabla)}>

          <div className="flex flex-wrap w-5/12 md:w-32 md:mt-2 pl-1 md:pl-2 md:mr-3 mb-6">
            <div>
              <label className="inline-flex items-center w-fit hover:cursor-pointer mt-3">
                <input
                  type="radio"
                  {...register("radioAumentaDisminuye", validacion)}
                  value={"aumenta"}
                />
                <span className="ml-2 w-full text-14 dark:text-white text-hercules-light-font-secondary">
                  Aumenta
                </span>
              </label>
            </div>
            <div>
              <label className="inline-flex items-center w-fit hover:cursor-pointer mt-3">
                <input
                  type="radio"
                  {...register("radioAumentaDisminuye", validacion)}
                  value={"disminuye"}
                />
                <span className="ml-2 w-full text-14 dark:text-white text-hercules-light-font-secondary">
                  Disminuye
                </span>
              </label>
            </div>

            <span
              className={`text-red-500 text-xs italic ${
                !errors.radioAumentaDisminuye && "opacity-0 invisible"
              }`}
            >
              {errors.radioAumentaDisminuye
                ? errors.radioAumentaDisminuye.message
                : "hidden"}
            </span>
          </div>

          <div className="flex flex-wrap w-5/12 md:w-32 md:mt-2 pl-1 md:pl-2 mb-6">
            <div>
              <label className="inline-flex items-center w-fit hover:cursor-pointer mt-3">
                <input
                  type="radio"
                  {...register("radioValorPorcentaje", {
                    onChange: () => {
                      setValue("montoValor", "$0");
                      setValue("montoPorcentaje", "0%");
                    },
                    ...validacion,
                  })}
                  value={"valor"}
                />
                <span className="ml-2 w-full text-14 dark:text-white text-hercules-light-font-secondary">
                  Valor
                </span>
              </label>
            </div>
            <div>
              <label className="inline-flex items-center w-fit hover:cursor-pointer mt-3">
                <input
                  type="radio"
                  {...register("radioValorPorcentaje", {
                    onChange: () => {
                      setValue("montoValor", "$0");
                      setValue("montoPorcentaje", "0%");
                    },
                    ...validacion,
                  })}
                  value={"porcentaje"}
                />
                <span className="ml-2 w-full text-14 dark:text-white text-hercules-light-font-secondary">
                  Porcentaje
                </span>
              </label>
            </div>

            <span
              className={`text-red-500 text-xs italic ${
                !errors.radioValorPorcentaje && "opacity-0 invisible"
              }`}
            >
              {errors.radioValorPorcentaje
                ? errors.radioValorPorcentaje.message
                : "hidden"}
            </span>
          </div>

          <div className="w-6/12 md:w-3/12 md:mt-2 md:pl-2 mr-3">
            <span className="w-full text-14 dark:text-white text-hercules-light-font-secondary mb-7">
              Monto
            </span>
            <>
              {watch("radioValorPorcentaje") === "" && (
                <input
                  placeholder="Valor/Porcentaje"
                  disabled
                  className="md:mb-11 mb-9 appearance-none block w-full bg-gray-300 text-gray-800 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                />
              )}
              {watch("radioValorPorcentaje") === "valor" && (
                <>
                  <input
                    placeholder="$0"
                    {...register("montoValor", {
                      validate: (value) => {
                        if (value === "" || value === "$" || value === "$0") {
                          return "Mayor a $0";
                        }
                        return true;
                      },
                      onChange: () => {
                        setValue(
                          "montoValor",
                          "$" +
                            formatter.format(
                              getValues("montoValor")?.replace(/\D/g, "")
                            )
                        );
                      },
                    })}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                  />
                  <span
                    className={`text-red-500 text-xs italic ${
                      !errors.montoValor && "opacity-0 invisible"
                    }`}
                  >
                    {errors.montoValor ? errors.montoValor.message : "hidden"}
                  </span>
                </>
              )}
              {watch("radioValorPorcentaje") === "porcentaje" && (
                <><input
                  placeholder="1 - 100"
                  {...register("montoPorcentaje", {
                    validate: (value) => {
                      const parsedValue = parseInt(value, 10);
                      if (isNaN(parsedValue) ||
                        parsedValue < 1 ||
                        parsedValue > 100) {
                        return "Mayor a 0%";
                      }
                      return true;
                    },
                    onChange: (e) => {
                      const inputValue = e.target.value.replace(/\D/g, "");
                      if (inputValue === "0") {
                        setValue("montoPorcentaje", "");
                      } else if (parseInt(inputValue, 10) > 100) {
                        setValue("montoPorcentaje", "100%");
                      } else {
                        setValue("montoPorcentaje", inputValue + "%");
                      }
                    },
                  })}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text" />
                  <span
                    className={`text-red-500 text-xs italic ${!errors.montoPorcentaje && "opacity-0 invisible"}`}
                  >
                    {errors.montoPorcentaje ? errors.montoPorcentaje.message : "hidden"}
                  </span></>

              )}
            </>
          </div>

          <div className="w-4/12 md:w-2/12 md:mt-8 ml-3 mt-6 md:mb-9 mb-6">
            <button
              type="submit"
              className="md:mr-1 mb-1 hover:scale-105 bg-hercules-dark-primary-button text-white font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-in-out transition-all duration-400"
            >
              Calcular
            </button>
          </div>
        </form> */}

      <div className="w-full md:w-2/12 mt-6">
        <button
          type="button"
          disabled={!botonGuardarHabilitado}
          onClick={() => abrirModal()}
          className={` ${
            !botonGuardarHabilitado
              ? "bg-gray-500"
              : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"
          }  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
        >
          Actualizar
        </button>
      </div>

      {showModalActualizar && (
        <ModalEditarAnexoContrato
          modalData={arrayIdTrabajadores}
          isAnexosContrato={isAnexosContrato}
          isVisible={showModalActualizar}
          onClose={() => setShowModalActualizar(false)}
          resetearFiltros={() => resetearFiltros()}
        />
      )}
    </>
  );
}
