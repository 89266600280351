import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useStateContext } from "../../../../contexts/ContextProvider";
import { axiosPrivate } from "../../../../common/axiosPrivate";

export default function ModalEditarPeriodo({ modalData, onClose }) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const [loadingModal, setLoadingModal] = useState(false);
  const dispatch = useDispatch();

  // const anyos = [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];

  // const [periodoDesde, setPeriodoDesde] = useState(anyos[0]); // Valor inicial del select "Periodo Desde"
  // const [periodoHastaOptions, setPeriodoHastaOptions] = useState(
  //   anyos.slice(anyos.indexOf(periodoDesde) + 1)
  // ); // Opciones del select "Periodo Hasta" basadas en el año seleccionado en "Periodo Desde"

  // const periodoHasta = periodoDesde + 1; // Año posterior al seleccionado en "Periodo Desde"

  // console.log("modalData", modalData)

  useEffect(() => {
    setLoadingModal(true);
    if (modalData) {
      reset({
        id: modalData.id,
        periodoDesde: modalData.anyoInicio,
        periodoHasta: modalData.anyoInicio + 1,
        diasVacacionesDisponibles: modalData.diasVacaciones,
        diasProgresivos: modalData.feriadoProgresivo,
        estado: modalData.activo === true ? "Activo" : "Inactivo",
      });
    }
  }, [dispatch]);

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const REACT_APP_API_HERCULES_SERVICE =
  process.env.REACT_APP_API_HERCULES_SERVICE;

const tokenRut = localStorage.getItem("token-rut");
const token = tokenRut.slice(0, 8);
const tokenUser = localStorage.getItem("token-user");
const periodoId = localStorage.getItem("periodoId");

const Authorization = {
  headers: {
    Authorization: `Bearer ${tokenUser}`,
    ClientId: `${token}`,
  },
};

  async function onSubmit(data) {
    // setBotonGuardarHabilitado(false)
    toast.loading("Editando Periodo", estilosAlerta);
    try {
      const requestBody = {
        periodoVacacionId: data.id,
        feriadoProgresivo: data.diasProgresivos,
        diasVacaciones: data.diasVacacionesDisponibles,
        activo: data.estado === "Activo" ? true : "False",
      };
      console.log("requestBody", requestBody)
      const response = await axiosPrivate.put(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/vacaciones/periodos`,
        requestBody,
        Authorization
      );
      console.log("response", response)
      if (response.status === 200) {
        setRefreshTable(!refreshTable);
        toast.dismiss();
        toast.success("Periodo editado con éxito", estilosAlerta);
        onClose();
        reset();
      }
      // setBotonGuardarHabilitado(true)
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(error.response.data.message ? error.response.data.message : "Error al editar Periodo", estilosAlerta);
      // setBotonGuardarHabilitado(true)
    }
  }


  return (
    <>
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="primerDivModal">
        <div className="segundoDivModal max-w-lg">
          {/*content*/}
          <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
            {/*header*/}
            <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">Editar Periodo</h3>
            </div>
            {/*body*/}
            <div className="relative p-6 overflow-auto flex-auto dark:bg-hercules-dark-bg-200">
              {/* {!loadingModal && <ModalSpinner />} */}
              <>
                {/* Primera fila modal */}

                <div className="md:flex md:flex-wrap">
                  {/* <div className="w-full md:w-2/12 md:pr-3 mb-9">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      ID
                    </label>
                    <input
                      disabled
                      {...register("id")}
                      name="id"
                      id="id"
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                  </div> */}

                  <div className="w-full md:w-5/12 md:pr-3 mb-9">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Periodo
                    </label>
                    <input
                      value={modalData.anyoInicio + " - " + (modalData.anyoInicio + 1)}
                      disabled
                      className="appearance-none block w-full bg-gray-400 text-gray-900 border border-gray-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                    </input>
                  </div>

                  <div className="w-full md:w-5/12 md:pl-3 mb-9">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Estado
                    </label>
                    <select
                      {...register("estado")}
                      value={watch("estado")}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option value="Activo">Activo</option>
                      <option value="Inactivo">Inactivo</option>
                    </select>
                  </div>
                </div>

                <div className="md:flex md:flex-wrap">
                  <div className="w-full md:w-5/12 md:pr-3 mb-9">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Días Vacaciones Disp.
                    </label>
                    <input
                      {...register("diasVacacionesDisponibles")}
                      name="diasVacacionesDisponibles"
                      id="diasVacacionesDisponibles"
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="number"
                      max={15}
                    />
                  </div>

                  <div className="w-full md:w-5/12 md:pl-3 mb-9">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Días Progresivos
                    </label>
                    <input
                      {...register("diasProgresivos")}
                      name="diasProgresivos"
                      id="diasProgresivos"
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="number"
                    />
                  </div>
                </div>

                <div className="md:flex md:flex-wrap">

                </div>
              </>
            </div>

            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-sm md:text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cerrar
              </button>
              <button
                className="bg-hercules-light-primary-button hover:shadow-lg hover:scale-105 text-white  font-bold uppercase text-sm md:text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                type="submit"
                >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-20 bg-black"></div>
      </form>
    </>
  );
}
