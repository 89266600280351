import React from "react";
import SimpleTable from "../../SimpleTable";
import ColumnFilter from "../../ColumnFilter";
import UtilsFunctions from "../../../utils/UtilsFunctions";

export default function ModalErrorEditarAnexosContratos({
  modalData,
  isVisible,
  onClose,
}) {
    
  const COLUMNS = [
    {
      Header: "RUT",
      accessor: (d) => UtilsFunctions.rutFormatter(d.rutTrabajador, { dots: true }),
      Filter: ColumnFilter,
    },
    {
        Header: "Trabajador",
        accessor: "nombreTrabajador",
        Filter: ColumnFilter,
      },  
    {
      Header: "Error",
      accessor: "mensajeError",
      Filter: ColumnFilter,
    },
  ];

  const headersCsv = [
    {
      key: "rutTrabajador",
      name: "RUT",
    },
    {
        key: "nombreTrabajador",
        name: "Trabajador",
      },  
    {
      key: "nombreTrabajador",
      name: "Error",
    },
  ];

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div className="primerDivModal">
        <div className="segundoDivModal max-w-6xl">
          <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
            {/*header*/}
            <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">
                Hay contratos que presentan errores
              </h3>
            </div>

            {/*body*/}
            <div className="relative p-6 overflow-auto flex-auto dark:bg-hercules-dark-bg-200">
              <p className="text-lg font-normal leading-normal mb-4 dark:text-white text-hercules-light-font-secondary">
                Los siguientes contratos presentan errores al actualizarse, por favor corrijalos e intente nuevamente.<br/> No se
                actualizaron los siguientes contratos:
              </p>

              <div className="w-full md:pr-3 mb-9">
                <SimpleTable
                  col={COLUMNS}
                  tableData={modalData}
                    exportCsv={() =>
                      UtilsFunctions.jsonToCSV3(
                        modalData,
                        headersCsv,
                        "Contratos_error_actualizar"
                      )
                    }
                  tableHeight="h-72"
                />
              </div>
            </div>

            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
