import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { getHaberesDT, limpiarInfoMenuEdicion } from "../../../Redux/Actions";
import { axiosPrivate } from "../../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import { useToaster } from "react-hot-toast/headless";
import { useForm } from "react-hook-form";
import ModalSpinner from "../../ModalSpinner";
export default function MantenedorEditarHaber({
  isVisible,
  onClose,
  modalData,
}) {
  const {
    register,
    reset,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const toaster = useToaster();

  const [habilitado, setHabilitado] = useState(undefined);
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const validacionTextInput = {
    required: { value: true, message: "Obligatorio" },
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHaberesDT());
    if (modalData) {
      reset({
        id: modalData.id,
        nombre: modalData.nombre,
        haberDtId: modalData.haberesDt.id,
        haberDtNombre: modalData.haberesDt.glosa,
        imponible: modalData.haberesDt.imponible ? "Si" : "No",
        tributable: modalData.haberesDt.tributable ? "Si" : "No",
        constituyeRemuneracion: modalData.haberesDt.constituyeRemuneracion
          ? "Si"
          : "No",
        radio: modalData.condicionalAsistenciaTotal
          ? "condicionalAsistenciaTotal"
          : modalData.pagoProporcionalDiasTrabajados
          ? "proporcional"
          : modalData.requiereAlmenosUnDiaTrabajado
          ? "requiereAlmenosUnDiaTrabajado"
          : "ninguno",
      });
      setHabilitado(modalData.activo);
      console.log("Modal data", modalData);
    }
  }, [modalData, dispatch]);

  const haberesDt = useSelector((state) => state.getHaberesDT);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("getHaberesDT"));
      reset({});
    };
  }, []);

  function handleSelectCategoriaLRE(e) {
    const categoriaLRE = haberesDt[e.target.selectedIndex - 1];
    setValue("categoriaLRE", categoriaLRE);
    setValue("imponible", categoriaLRE.imponible ? "Si" : "No");
    setValue("tributable", categoriaLRE.tributable ? "Si" : "No");
    setValue(
      "constituyeRemuneracion",
      categoriaLRE.constituyeRemuneracion ? "Si" : "No"
    );
  }

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  async function onSubmit(data) {
    setBotonGuardarHabilitado(false)
    if (!modalData.editable) {
      toast.dismiss();
      toast.error("Este haber no se puede editar", estilosAlerta);
      setBotonGuardarHabilitado(true)
      return null;
    }
    toast.loading("Editando Haber", estilosAlerta);
    try {
      setBotonGuardarHabilitado(true)
      const payload = {
        haberId: data.id,
        nombre: data.nombre,
        haberDtId: data.haberDtId,
        condicionalAsistenciaTotal:
          data.radio === "condicionalAsistenciaTotal" ? true : false,
        proporcional: data.radio === "proporcional" ? true : false,
        requiereAlmenosUnDiaTrabajado: data.radio === "requiereAlmenosUnDiaTrabajado" ? true : false,
        habilitado: habilitado,
      };
      await axiosPrivate.put(
        `${api_service}/api/v1/trabajadores/actualizarhaber`,
        payload,
        Authorization
      );
      onClose();
      setRefreshTable(!refreshTable);
      toast.dismiss();
      toast.success("Haber editado con éxito", estilosAlerta);
      reset();
    } catch (error) {
      setBotonGuardarHabilitado(true)
      console.log("error", error);
      toast.dismiss();
      toast.error(error.response.data.message? error.response.data.message : "Error al editar Haber", estilosAlerta);
    }
  }

  const loadingModal =
    haberesDt?.length > 0 && getValues("haberDtNombre") !== "";

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="primerDivModal">
          <div className="segundoDivModal max-w-2xl">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
                <h3 className="titulo-Modal">Editar Haber</h3>
              </div>
              {/*body*/}
              <div
                className={`relative p-6 flex-auto dark:bg-hercules-dark-bg-200 ${
                  !loadingModal ? "overflow-hidden" : "overflow-auto"
                }`}
              >
                {!loadingModal ? (
                  <div className="h-fix">
                    <ModalSpinner />
                  </div>
                ) : (
                  <>
                    {!modalData.editable && (
                      <p className="dark:text-white text-hercules-light-font-secondary mb-6 font-semibold">
                        Este haber no se puede editar
                      </p>
                    )}
                    <div className="div-Inputs-EditarHaber">
                      <label
                        className="block uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Nombre
                      </label>
                      <input
                        disabled={!modalData.editable}
                        {...register("nombre", validacionTextInput)}
                        className={`appearance-none block w-full text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500 ${
                          !modalData.editable
                            ? "bg-gray-300 border-gray-300"
                            : "bg-gray-200 border-gray-200"
                        }`}
                        type="text"
                      />
                      <span
                        className={`text-red-500 dark:text-red-400 text-xs italic ${
                          !errors.nombre && "opacity-0 invisible"
                        }`}
                      >
                        {errors.nombre ? errors.nombre.message : "hidden"}
                      </span>
                    </div>

                    <div className="div-Inputs-EditarHaber">
                      <label
                        className="block uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Categoría L.R.E
                      </label>
                      <select
                        disabled={!modalData.editable}
                        {...register("haberDtId", {
                          required: {
                            value: true,
                            message: "Debe seleccionar una Categoría",
                          },
                          onChange: (e) => handleSelectCategoriaLRE(e),
                        })}
                        name="haberDtId"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                      >
                        <option value="" hidden key={watch("haberDtId")}>
                          {watch("haberDtNombre")}
                        </option>
                        {haberesDt.map((haberDT, i) => (
                          <>
                            <option key={i} value={haberDT.id}>
                              {haberDT.glosa}
                            </option>
                          </>
                        ))}
                      </select>
                      <span
                        className={`text-red-500 dark:text-red-400 text-xs italic ${
                          !errors.haberDtId && "opacity-0 invisible"
                        }`}
                      >
                        {errors.haberDtId ? errors.haberDtId.message : "hidden"}
                      </span>
                    </div>

                    <div className="flex flex-wrap mb-4 md:mb-6">
                      <div className="md:h-full w-1/2 md:w-2/6 rounded-lg">
                        <label
                          className="block uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Imponible
                        </label>
                        <input
                          {...register("imponible")}
                          disabled
                          className="w-20 md:w-24 appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-last-name"
                          type="text"
                        />
                      </div>

                      <div className="md:h-full w-1/2 md:w-2/6 rounded-lg">
                        <label
                          className="block uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Tributable
                        </label>
                        <input
                          {...register("tributable")}
                          disabled
                          className="w-20 md:w-24 appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-last-name"
                          type="text"
                        />
                      </div>

                      <div className="md:h-full w-full md:w-2/6 rounded-lg mt-4 md:mt-0">
                        <label
                          className="block uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Constituye Remuneración
                        </label>
                        <input
                          {...register("constituyeRemuneracion")}
                          disabled
                          className="w-20 md:w-24 appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-last-name"
                          type="text"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        className={`inline-flex items-center w-fit ${
                          !modalData.editable ? "" : "hover:cursor-pointer"
                        } mt-3`}
                      >
                        <input
                          disabled={!modalData.editable}
                          type="radio"
                          {...register("radio")}
                          value={"proporcional"}
                        />
                        <span className="ml-2 dark:text-white text-hercules-light-font-secondary w-full text-14">
                          Se paga proporcional a los días trabajados
                        </span>
                      </label>
                    </div>

                    <div>
                      <label
                        className={`inline-flex items-center w-fit ${
                          !modalData.editable ? "" : "hover:cursor-pointer"
                        }  mt-3`}
                      >
                        <input
                          disabled={!modalData.editable}
                          type="radio"
                          {...register("radio")}
                          value={"condicionalAsistenciaTotal"}
                        />
                        <span className="ml-2 dark:text-white text-hercules-light-font-secondary w-full text-14">
                          Condicional a asistencia total
                        </span>
                      </label>
                    </div>

                    <div>
                      <label
                        className={`inline-flex items-center w-fit  ${
                          !modalData.editable ? "" : "hover:cursor-pointer"
                        } mt-3`}
                      >
                        <input
                          disabled={!modalData.editable}
                          type="radio"
                          {...register("radio")}
                          value={"requiereAlmenosUnDiaTrabajado"}
                        />
                        <span className="ml-2 dark:text-white text-hercules-light-font-secondary w-full text-14">
                          Requiere al menos un día trabajado
                        </span>
                      </label>
                    </div>

                    <div>
                      <label
                        className={`inline-flex items-center w-fit  ${
                          !modalData.editable ? "" : "hover:cursor-pointer"
                        } mt-3`}
                      >
                        <input
                          disabled={!modalData.editable}
                          type="radio"
                          {...register("radio")}
                          value={"ninguno"}
                        />
                        <span className="ml-2 dark:text-white text-hercules-light-font-secondary w-full text-14">
                          Ninguno de los anteriores
                        </span>
                      </label>
                    </div>

                    <div className="flex flex-wrap mt-6">
                      <span className="uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold my-auto mr-10 mb-1 md:mb-0 ">
                        Habilitado
                      </span>
                      <label
                        className={`inline-flex relative items-center ${
                          !modalData.editable ? "" : "hover:cursor-pointer"
                        }`}
                      >
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          checked={habilitado}
                          readOnly
                          disabled={!modalData.editable}
                        />
                        <div
                          disabled={!modalData.editable}
                          onClick={() => {
                            if (modalData.editable) {
                              setHabilitado(!habilitado);
                            }
                          }}
                          className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                        ></div>
                      </label>
                    </div>
                  </>
                )}
              </div>
              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="dark:text-white text-hercules-light-font-secondary hover:scale-110 font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  Cerrar
                </button>
                <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </form>
    </>
  );
}
