import React, { useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  TbArrowsSort,
  TbSortAscending,
  TbSortDescending,
} from "react-icons/tb";
import GlobalFilter from "../../GlobalFilter";
import { BiSearch } from "react-icons/bi";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdLastPage,
  MdFirstPage,
} from "react-icons/md";
import { axiosPrivate } from "../../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import { useStateContext } from "../../../contexts/ContextProvider";
import UtilsFunctions from "../../../utils/UtilsFunctions";
export default function ImprimirLiquidacionesTable({
  col,
  tableData,
  periodoSeleccionadoId,
  periodoMesSeleccionado,
  periodoAnyoSeleccionado,
  rutTrabajador,
  tipo,
}) {
  // eslint-disable-next-line
  const columns = useMemo(() => col, []);
  const data = useMemo(() => [...tableData], [tableData]);

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const { screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    setPageSize,
    pageOptions,
    state,
    rows,
    selectedFlatRows,
    state: { selectedRowIds },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          disableGlobalFilter: true,
        },
        ...columns,
      ]);
    }
  );

  // console.log("rows", rows);

  const { pageIndex, pageSize } = state;

  const { globalFilter } = state;

  const [input, setInput] = useState({
    tipoImpresion: "imprimirUnArchivo",
  });

  function selectTipoImpresion(e) {
    e.preventDefault();
    try {
      setInput({
        ...input,
        tipoImpresion: e.target.value,
      });
    } catch (error) {
      console.log(error);
    }
  }

  // console.log("Input", input);

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  async function imprimirLiquidacionesSeleccionadas() {
    toast.loading("Imprimiendo Liquidaciones", estilosAlerta);
    const arrayIdLiquidaciones = [];
    selectedFlatRows.map((e) => {
      arrayIdLiquidaciones.push(e.original.liquidacionId);
    });
    if (arrayIdLiquidaciones.length === 0) {
      toast.dismiss();
      toast.error(
        "Debe seleccionar por lo menos una Liquidación",
        estilosAlerta
      );
      return null;
    }
    const data = {
      liquidacionesId: arrayIdLiquidaciones,
      periodoId: periodoSeleccionadoId,
    };
    if (input.tipoImpresion == "imprimirUnArchivo") {
      const centroCosto = localStorage.getItem("centroCosto");
      axiosPrivate({
        url: `${api_service}/api/v1/trabajadores/imprimirLiquidacion`,
        method: "POST",
        data: data,
        maxBodyLength: Infinity,
        headers: { Authorization: `Bearer ${tokenUser}`, ClientId: `${token}` },
        responseType: "blob", // importante
        onDownloadProgress: (progressEvent) => {
          Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Liquidación${centroCosto ? "_" + centroCosto : "" }_${periodoMesSeleccionado}-${periodoAnyoSeleccionado}.pdf`
          )
              
          document.body.appendChild(link);
          link.click();
          toast.dismiss();
          toast.success("Liquidaciones impresas con éxito", estilosAlerta);
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss();
          toast.error("Error al imprimir Liquidaciones", estilosAlerta);
        });
    }
    if (input.tipoImpresion == "imprimirPorSeparado") {
      const centroCosto = localStorage.getItem("centroCosto");
      axiosPrivate({
        url: `${api_service}/api/v1/trabajadores/imprimirLiquidacionSeparada`,
        method: "POST",
        data: data,
        maxBodyLength: Infinity,
        headers: { Authorization: `Bearer ${tokenUser}`, ClientId: `${token}` },
        responseType: "blob", // importante
        onDownloadProgress: (progressEvent) => {
          Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
             link.setAttribute(
                  "download",
                  `Liquidación_${centroCosto}_${periodoMesSeleccionado}-${periodoAnyoSeleccionado}.zip`
                )
              
          document.body.appendChild(link);
          link.click();
          toast.dismiss();
          toast.success("Liquidaciones impresas con éxito", estilosAlerta);
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss();
          toast.error("Error al imprimir Liquidaciones", estilosAlerta);
        });
    }
  }

  return (
    <>
      <div className="bg-white dark:bg-hercules-dark-bg-100 border-t  border-l border-r border-gray-300 dark:border-dark-bg-200 h-12 flex rounded-t-md p-3">
        <>
          <span className="ml-auto ">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </span>
          <span className="mx-3 pt-1 text-xl dark:text-dark-text-100 text-black hover:cursor-pointer">
            <BiSearch />
          </span>
        </>
      </div>

      <div className="h-fix overflow-auto border-x-1  bg-white dark:bg-hercules-dark-bg-200  dark:border-dark-bg-200 border-gray-300 border-t-1">
        <table {...getTableProps()} className="w-full rounded-lg">
          <thead className="text-left sticky top-0 border-b-1 border-b-hercules-light-secondary dark:border-b-hercules-dark-secondary-button bg-hercules-light-table-header dark:bg-hercules-dark-bg-200">
            {headerGroups.map((headerGroup) => {
              const { key, ...restHeaderGroup } =
                headerGroup.getHeaderGroupProps();
              return (
                <>
                  <tr {...restHeaderGroup} className="h-10 flex-auto">
                    {headerGroup.headers.map((column) => {
                      const { key, ...restColumn } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );
                      return (
                        <React.Fragment key={key}>
                          <th
                            {...restColumn}
                            className="py-2 px-4 text-hercules-light-font-secondary font-medium dark:text-white"
                          >
                            <div className="flex">
                              <span className="">
                                {column.render("Header")}
                              </span>
                              <span className="ml-1 mt-auto mb-auto">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TbSortDescending />
                                  ) : (
                                    <TbSortAscending />
                                  )
                                ) : (
                                  <TbArrowsSort />
                                )}
                              </span>
                            </div>
                          </th>
                        </React.Fragment>
                      );
                    })}
                  </tr>
                  <tr {...headerGroup.getHeaderGroupProps()} className="h-10">
                    {headerGroup.headers.map((column) => {
                      const { key, ...restColumn } = column.getHeaderProps();
                      return (
                        <th {...restColumn} className="pl-4 pb-4">
                          <div className="w-fit pr-4">
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()} className="h-full ">
              {page.map((row, i) => {
                prepareRow(row);
              return (
                <React.Fragment>
                  <tr
                    key={i}
                    {...row.getRowProps()}
                    className="group bg-white dark:bg-hercules-dark-bg-200   dark:text-dark-text-100 dark:border-b-hercules-dark-secondary-button border-b border-hercules-light-secondary text-black h-12 dark:hover:bg-white dark:hover:bg-opacity-10 transition duration-100 ease-in-out hover:bg-light-accent hover:bg-opacity-20"
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps}
                          className="py-2 px-4 text-base font-normal"
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className=" border-1 dark:border-t-hercules-dark-secondary-button border-t-hercules-light-secondary border-gray-300 dark:border-dark-bg-200 dark:bg-hercules-dark-bg-200   rounded-b-md h-14 flex bg-white">
          <div className="ml-auto py-3 flex">
            <p className="hidden sm:block pt-1 text-black dark:text-white">
              Filas por página
            </p>
            <select
              name=""
              id=""
              className="ml-2 h-8 pt-1.5 text-sm bg-white border rounded-sm"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 20, 30, 40, 50].map((pageSize, i) => (
                <option key={i} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block p-4 text-black dark:text-white">
            Página {pageIndex + 1} de {pageOptions.length}
          </div>
          <div className="flex mr-4 pl-4">
            <button
              className="bg-white text-black dark:text-white dark:bg-opacity-0 rounded-full hover:bg-gray-200 hover:text-black my-2 w-8 mr-6"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <MdFirstPage className="pl-1 text-2xl" />
            </button>
            <button
              className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 mr-6 w-8"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <MdArrowBackIos className="ml-2" />
            </button>
            <button
              className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 mr-6 w-8"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <MdArrowForwardIos className="ml-2" />
            </button>
            <button
              className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 w-8"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <MdLastPage className="pl-1 text-2xl" />
            </button>
          </div>
        </div>

      <div className="md:flex mt-6 md:mt-3 ">
        <div className="w-8/12 md:pr-2 md:w-3/12">
          <label
            htmlFor="periodoId"
            className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
          >
            Tipo de impresión
          </label>
          <select
            onChange={(e) => selectTipoImpresion(e)}
            value={input.tipoImpresion}
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option value="imprimirUnArchivo">Un único archivo</option>

            <option value="imprimirPorSeparado">Archivos separados</option>
          </select>
        </div>

        <div className="mt-6 md:mx-2 ">
          <button
            onClick={() => imprimirLiquidacionesSeleccionadas()}
            className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-in-out transition-all duration-400"
          >
            Imprimir Liquidaciones
          </button>
        </div>
      </div>
    </>
  );
}
